import {
  exponentialDelay,
  isNetworkOrIdempotentRequestError,
} from 'axios-retry'

const ns = '__axios_retry'

const isRetryable = (error) =>
  [
    (error) => error.response?.status === 429, // too many requests
    (error) => error instanceof RetryableError,
    isNetworkOrIdempotentRequestError,
  ].some((check) => check(error))

const getState = (config) => {
  const state = config[ns] || {}
  state.count = state.count || 0
  config[ns] = state
  return state
}

export class RetryableError extends Error {
  constructor(message, response) {
    super(message)
    Object.assign(this, response)
  }
}

export default (axios, { retries = 3 } = {}) => {
  const retry = (error) => {
    const { config } = error

    if (!config || !isRetryable(error)) return Promise.reject(error)

    const state = getState(config)

    if (state.count >= retries) return Promise.reject(error)

    const delay = exponentialDelay((state.count += 1))

    return new Promise((resolve, reject) =>
      setTimeout(() => axios(config).then(resolve, reject), delay)
    ).catch(retry)
  }

  axios.interceptors.request.use((config) => {
    getState(config).lastRequestTime = new Date()
    return config
  })

  const instance = (config) => axios(config).catch(retry)

  // the mothods should have been bound already.
  return Object.assign(instance, axios)
}
