export default [
  {
    path: '/',
    redirect: { name: 'nav', params: { levels: ['rent'] } },
  },
  {
    path: '/agents/:organizationId/:id',
    name: 'agentHome',
    component: () => import('./pages/AgentView.vue'),
  },
  {
    path: '/:levels*/detail/:id',
    name: 'detail',
    component: () => import('./pages/DetailView.vue'),
  },
  {
    path: '/:levels*/list',
    name: 'list',
    component: () => import('./pages/ListView.vue'),
  },
  {
    path: '/:levels*',
    name: 'nav',
    component: () => import('./pages/NavView.vue'),
    alias: '/:levels*',
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('./pages/NotFound.vue'),
  },
]
