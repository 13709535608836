<style>
/* main */

.l-toasts {
  position: absolute;
  left: 50%;
  top: 60px;
  transform: translate(-50%);
  display: flex;
  justify-content: center;
  flex-flow: column;
  z-index: 10;
}
.l-toasts .toast {
  pointer-events: none;
  top: 0;
}
.l-toasts .toast .move {
  pointer-events: auto;
  position: relative;
  padding: 10px 0;
}

@media (max-width: 576px) {
  .l-toasts {
    width: 95%;
  }
}

/* message */

.l-toasts .message {
  align-items: center;
  background: #ffffff;
  padding: 20px 30px;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
.l-toasts .message svg {
  min-width: 36px;
  height: 36px;
  margin-right: 20px;
}
.l-toasts .message p {
  white-space: pre-line;
  overflow: hidden;
}

/* animations */

.l-toasts .toast {
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.l-toasts .toast .move {
  transition: transform 0.4s ease;
}
.l-toasts .toast-enter-from {
  opacity: 0;
  transform: translateY(-25%);
}
.l-toasts .toast-leave-active {
  z-index: -1;
}
.l-toasts .toast-leave-to {
  opacity: 0;
  transform: translateY(25%);
}
</style>

<template>
  <transition-group
    class="l-toasts"
    name="toast"
    tag="div"
    @before-enter="showStart"
    @after-enter="showEnd"
    @mouseenter="toastsEnter"
    @mouseleave="toastsLeave"
    @after-leave="hideEnd"
  >
    <div
      class="toast"
      v-for="(toast, index) in toasts"
      :key="toast.id"
      :id="index.toString()"
    >
      <div class="move">
        <a class="message u-row" @click="toastClick(toast)">
          <component
            :is="icon[toast.type].component"
            :class="icon[toast.type].class"
          />
          <p class="u-flex">{{ toast.message }}</p>
        </a>
      </div>
    </div>
  </transition-group>
</template>

<script lang="ts">
import toasts, { Toast } from '@/stores/toasts'

import iconInfo from '@/assets/icons/info.svg?component'
import iconSuccess from '@/assets/icons/success.svg?component'

const max = 2

export default {
  setup() {
    return toasts
  },

  components: {
    iconInfo,
    iconSuccess,
  },

  data() {
    return {
      showing: false,
      hovered: false,
      toasts: [] as Toast[],
      icon: {
        info: {
          component: 'iconSuccess',
          class: 'cl-green',
        },
        warn: {
          component: 'iconInfo',
          class: 'cl-orange',
        },
        error: {
          component: 'iconInfo',
          class: 'cl-red',
        },
      },
    }
  },

  methods: {
    toastsEnter() {
      this.hovered = true
      this.toasts.forEach((toast) => toast.pause())
    },

    toastsLeave() {
      this.hovered = false
      this.toasts.slice().forEach((toast) => toast.resume())
      !this.showing && this.update()
    },

    toastClick(toast: Toast) {
      if (this.showing && this.toasts[0] === toast) return // do not remove appearing toast
      this.remove(toast)
      this.update()
    },

    showStart() {
      this.showing = true
    },

    showEnd() {
      this.showing = false
      !this.hovered && this.update()
    },

    hideEnd() {
      setTimeout(
        () => this.hovered && !this.$el.matches(':hover') && this.toastsLeave()
      ) // trigger toastsLeave if element is not hovered anymore
    },

    update() {
      if (!this.queue.length) return
      if (this.toasts.length === max) this.toasts.pop()
      const item = this.queue.shift()
      if (item) {
        if (item.onlyIfNotShown && this.toasts.length) return
        item.wait(this.remove)
        this.hovered && item.pause()
        this.toasts.unshift(item)
      }
    },

    add() {
      if (this.showing) return
      this.update()
    },

    remove(toast: Toast) {
      const index = this.toasts.indexOf(toast)
      if (index === -1) return
      this.toasts.splice(this.toasts.indexOf(toast), 1)
    },
  },

  watch: {
    'queue.length': {
      immediate: true,
      handler(current, previous) {
        if (current > previous) this.add() // item is pushed to queue
      },
    },
  },
}
</script>
