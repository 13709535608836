import type { AxiosRequestConfig } from 'axios'

// import { boundsToMinMax } from '@/utils/geo'

interface Config
  extends Record<string, (...args: any[]) => AxiosRequestConfig> {}

const config: Config = {
  meta(path) {
    return {
      method: 'GET',
      url: '/v1/page/meta',
      params: { path },
    }
  },
  list(path, filters, limit, skip) {
    return {
      method: 'GET',
      url: '/v1/page/list',
      params: { path, limit, skip, ...filters },
    }
  },
  agent(id, organizationId) {
    return {
      url: `/v1/agents/${organizationId}/${id}/profiles`,
    }
  },
  property(id) {
    return {
      url: `/v1/property/${id}`,
    }
  },
}

export default config
