import type { Plugin } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faMapMarkerAlt,
  faPhone,
  faLink,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// @ts-ignore
library.add(faMapMarkerAlt, faPhone, faLink, faChevronLeft, faChevronRight)

export const plugin: Plugin = {
  install(app) {
    app.component('FontAwesomeIcon', FontAwesomeIcon)
  },
}
