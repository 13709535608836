<style scoped>
.l-popup {
  position: fixed;
  overflow: auto;
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(102, 102, 102, 0.31);
  box-shadow: 0 0 16px 3px rgba(0, 0, 0, 0.5);
}
.l-popup > :deep(*) {
  margin: auto;
  border-radius: 6px;
  flex-shrink: 0;
}

.l-popup > :first-child {
  position: relative;
}

.l-popup .button-close {
  width: 24px;
  height: 24px;
  color: var(--cl-placeholder);
  position: absolute;
  top: 20px;
  right: 20px;
}

.close {
  position: absolute;
  right: 15px;
  top: 12px;
}
svg.close {
  fill: var(--cl-placeholder);
  width: 40px;
}
</style>

<template>
  <popup-view
    ref="popup"
    class="l-popup u-stretch"
    @click="handleClickOutside"
  />
</template>

<script lang="ts">
import type { ComponentPublicInstance } from 'vue'
export default {
  methods: {
    /**
     * When the user clicks on the popup-view,
     * the dark area surrounding the popup it
     * should close the popup
     */
    handleClickOutside(event: Event) {
      if (event.target === (this.$refs.popup as ComponentPublicInstance).$el)
        this.$popup.hide({})
    },

    /**
     * When the user presses the escape key
     * it should close the popup
     */
    handleEscape({ key }: KeyboardEvent) {
      if (['Escape', 'Esc'].includes(key)) this.$popup.hide({})
    },
  },

  watch: {
    // If the popup is active, listen for the escape key
    '$popup.isActive': function (isActive) {
      if (isActive)
        document.addEventListener('keyup', this.handleEscape, { once: true })
      else document.removeEventListener('keyup', this.handleEscape)
    },
  },
}
</script>
