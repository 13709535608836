<script setup lang="ts">
import { ref, watch } from 'vue'
import { event } from 'vue-gtag'

import sentry from '@/modules/sentry'
import Popup from '@/modules/popup'

import UISpinner from '@/components/ui/Spinner.vue'

import itemClose from '@/components/popups/items/close.vue'

import { use as useForm } from '@/stores/form'

const props = defineProps(['app'])

const store = useForm()

const submitting = ref(false)

const inputEmail = ref<HTMLInputElement | null>(null)
const inputPhone = ref<HTMLInputElement | null>(null)
const form = ref<HTMLFormElement | null>(null)

watch(
  () => store.email,
  () => inputEmail.value?.setCustomValidity('')
)
watch(
  () => store.phone,
  () => inputPhone.value?.setCustomValidity('')
)

function onError(err: { code: 'INVALID_PHONE' | 'INVALID_EMAIL' }) {
  if (err.code == 'INVALID_EMAIL') {
    inputEmail.value?.setCustomValidity('メールアドレスが正しくありません')
  } else if (err.code == 'INVALID_PHONE') {
    inputPhone.value?.setCustomValidity('電話番号が正しくありません')
  }
  form.value?.reportValidity()
}

function validateForm() {
  return form.value?.reportValidity()
}

function submit() {
  store.$patch({ confirmEmail: store.email })

  if (store.purpose !== 'その他問い合わせ') {
    store.$patch({ notes: '' })
  }

  try {
    if (!validateForm()) {
      event('submit_invalid_inquiry')
      return
    }

    store.requestedAt = new Date().toISOString()

    return store.submit({ app: props.app }).then((resp) => {
      if (resp.code === 'email_verification_needed') {
        Popup.hide()
        return Popup.show('inquiryValidation', { app: props.app })
      } else if (resp.code == 'succeeded') {
        event('submit_inquiry', {
          enum: store.phone ? 'with_phone' : 'default',
        })

        Popup.hide()
        return Popup.show('inquirySuccess', {
          link: (store.result as { code: 'succeeded'; shareUrl: string })
            .shareUrl,
          title: '送信しました',
          message:
            '株式会社オープンルームの担当者が確認の上、<br/>ご回答いたします',
        })
      } else if (resp.code == 'failed') {
        onError(resp.error)
      }
    })
  } catch (err) {
    sentry.captureException(err)
  } finally {
    submitting.value = false
  }
}
</script>

<template>
  <div class="p-inquiry">
    <item-close @click="$popup.hide()" />

    <h1 class="h2 t-center w7">担当者へのお問い合わせ</h1>

    <form class="containerForm" ref="form" novalidate>
      <h2 class="h4 w7">お問い合わせ内容<span class="required">必須</span></h2>
      <div class="options form u-col">
        <div class="u-row option">
          <input
            type="radio"
            id="reason1"
            name="reason"
            value="もっと別の物件も見たい"
            v-model="store.purpose"
            required
          />
          <label for="reason1">もっと別の物件も見たい</label>
        </div>
        <div class="u-row option">
          <input
            type="radio"
            id="reason2"
            name="reason"
            value="気になる物件がある"
            v-model="store.purpose"
          />
          <label for="reason2">気になる物件がある</label>
        </div>
        <div class="u-row option">
          <input
            type="radio"
            id="reason3"
            name="reason"
            value="物件を見学したい"
            v-model="store.purpose"
          />
          <label for="reason3">物件を見学したい</label>
        </div>
        <div class="u-row option">
          <input
            type="radio"
            id="reason4"
            name="reason"
            value="その他問い合わせ"
            v-model="store.purpose"
          />
          <label for="reason4">その他問い合わせ（詳細記入必須）</label>
        </div>
        <div class="details" v-if="store.purpose === 'その他問い合わせ'">
          <textarea
            v-model="store.notes"
            placeholder="自由記載"
            required
          ></textarea>
        </div>
      </div>

      <h2 class="h4 w7">個人情報</h2>
      <div class="form u-col">
        <div class="field u-col">
          <label class="u-row">
            <span class="cl-gray-dark w7">氏名</span>
            <span class="required">必須</span>
          </label>
          <div class="multi u-row">
            <input
              type="text"
              class="i-box u-flex"
              required
              name="lastName"
              placeholder="森山"
              v-model="store.lastName"
            />
            <input
              type="text"
              class="i-box u-flex"
              required
              name="firstName"
              placeholder="太郎"
              v-model="store.firstName"
            />
          </div>
        </div>
        <div class="field u-col">
          <label class="u-row">
            <span class="cl-gray-dark w7">Eメール</span>
            <span class="required">必須</span>
          </label>
          <input
            ref="inputEmail"
            type="email"
            class="i-box u-flex"
            required
            name="email"
            placeholder="demo@openrm.co.jp"
            v-model="store.email"
          />
        </div>
        <div class="field u-col">
          <label class="u-row">
            <span class="cl-gray-dark w7">電話番号</span>
          </label>
          <input
            ref="inputPhone"
            type="text"
            class="i-box u-flex"
            name="phone"
            placeholder="000-0000-0000"
            v-model="store.phone"
          />
        </div>
      </div>
    </form>

    <p class="policy t-center cl-gray">
      <a
        class="txt-link small"
        href="https://openrm.co.jp/policy.html"
        target="_blank"
        >個人情報の取り扱い</a
      ><span class="small">同意の上、送信してください</span>
    </p>

    <UISpinner v-if="submitting" />
    <div class="u-row buttons" v-else>
      <a class="btn secondary" @click="$popup.hide()"
        ><span>キャンセル</span></a
      >
      <button type="submit" class="btn default" @click="submit">
        <span>次へ</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.p-inquiry {
  margin: auto;
  padding: 33px 40px;
  background: white;
  max-height: 100%;
}

@media (max-width: 750px) {
  .p-inquiry {
    width: 100%;
    border-radius: 0 !important;
  }
}

.containerForm {
  overflow: auto;
  min-height: 200px;
  height: calc(100% - 170px);
}

.form {
  background-color: var(--cl-gray-50);
  padding: 24px 32px;
}

.options {
  align-items: baseline;
}
.options .option {
  height: 19px;
  margin-top: 12px;
}

input[type='radio'] {
  margin: 0px 10px 0px 0px;
}

.h4 {
  margin: 32px 0px 16px;
}

.field {
  width: 100%;
  align-items: baseline;
  margin-top: 12px;
}

.field label {
  margin-bottom: 8px;
}

.field .multi {
  max-width: 100%;
}

.field .multi input:not(:first-child) {
  margin-left: 8px;
}

.field > input {
  width: 100%;
}

.details {
  width: 100%;
}
.details textarea {
  border-radius: 2px;
  border: 1px solid var(--cl-powder-gray);
  margin-top: 12px;
  padding: 10px 12px;
  width: 100%;
}

.required {
  background: var(--cl-orange);
  color: var(--cl-white);
  border-radius: 2px;
  font-size: 10px;
  padding: 1px 4px;
  margin-left: 6px;
}

.txt-link {
  display: inline-block;
  margin-top: 32px;
}

.buttons {
  justify-content: space-around;
  margin: 16px 27px;
}
.btn {
  max-width: 200px;
  min-width: 150px;
  width: 100%;
}
.btn + .btn {
  margin-left: 8px;
}
</style>
